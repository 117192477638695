

<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay :show="show" rounded="sm">

      <h2>{{ $t('Warehouse') }}</h2>
      <div v-if="respData.warehouse">
        <b-row v-if="respData.warehouse.length > 0" class="match-height">
          <b-col xl="3" md="4" sm="6" v-for="item in respData.warehouse" :key="item._id">
            <statistic-card-horizontal color="success" icon="BoxIcon" :statistic="item.total"
              :statistic-title="showFromCurrentLanguage(item.name)" />
          </b-col>
        </b-row>
        <p v-if="respData.warehouse.length === 0">{{ $t('No matching records found') }}</p>
      </div>

      <h2>{{ $t('Branch/Store') }}</h2>
      <div v-if="respData.branch">
        <b-row class="match-height">
          <b-col xl="3" md="4" sm="6">
            <statistic-card-horizontal color="primary" icon="HomeIcon" :statistic="onlineStore"
              :statistic-title="$t('Online Store')" />
          </b-col>
          <b-col xl="3" md="4" sm="6" v-for="item in respData.branch" :key="item._id">
            <statistic-card-horizontal color="primary" icon="HomeIcon" :statistic="item.total"
              :statistic-title="showFromCurrentLanguage(item.name)" />
          </b-col>
        </b-row>
      </div>

    </b-overlay>
  </div>
</template>
  
<script>
import store from '@/store'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

import {
  BCol,
  BRow,
  BOverlay,
} from 'bootstrap-vue'
import storeModule from '@/store/services/eCommerce/mainWarehouse'

const STORE_MODULE_NAME = 'mainWarehouse'

export default {
  components: {
    BCol,
    BRow,
    BOverlay,
    StatisticCardHorizontal,
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.data : {
        warehouse: [],
        branch: [],
      }
    },
    onlineStore() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.onlineStore : 0
    },
  },
  created() {
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, {})
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // 
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  methods: {
    showFromCurrentLanguage(data) {
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },
  }

}
</script>
  
<style></style>
  